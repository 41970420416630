
import { defineComponent } from 'vue'
import UserModel from '@/models/UserModel'

export default defineComponent({
  name: 'Forgot Password',
  data() {
    return {
      emailRequest: '',
      errorFieldEmail: false,
      errorEmailMessage: '',
      requestProcess: false,
      requestSuccess: false,
    }
  },
  computed: {
    checkEmail(): boolean {
      if (
        //eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailRequest)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async submitRequest() {
      this.requestProcess = true
      await UserModel.requestResetPassword(this.emailRequest)
        .then(res => {
          this.requestProcess = false
          this.requestSuccess = true
        })
        .catch(err => {
          this.requestProcess = false
          this.errorFieldEmail = true
          this.errorEmailMessage = err.response.data.errors[0].message
        })
    },
  },
})
