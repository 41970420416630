<template>
  <div
    class="container flex flex-col justify-center items-center text-center min-h-[85vh]"
  >
    <svg
      aria-hidden="true"
      focusable="false"
      data-prefix="fas"
      data-icon="spinner"
      class="w-10 h-10 animate-spin"
      role="img"
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 512 512"
    >
      <path
        fill="#00446F"
        d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
      ></path>
    </svg>
    <p class="text-[#00446F] font-solusi font-semibold mt-3">
      Memuat data...
    </p>
    <p class="text-[#00446F] font-solusi font-semibold">
      Mohon jangan lakukan aktivitas sampai data berhasil dimuat...
    </p>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import { from, of } from 'rxjs'
import { mergeMap, map, takeLast, switchMap } from 'rxjs/operators'
import MigrateUserDataModel from '@/models/MigrateUserDataModel'
import { catchError } from 'rxjs/operators'
import { ExistingUserData } from '@/dto/ImportDataUser'
import Auth, { Token } from '@/utils/Auth'

type Key = Exclude<keyof ExistingUserData, 'success'>
type UserData = ExistingUserData[Key]

const excludeKey = ['accessory', 'voucher', 'coin_log', 'success', 'message']

type Error = {
  name: string
  data: UserData
}

export default defineComponent({
  name: 'ForgotPassword',
  data() {
    return {
      progress: 0,
      dataLength: 0,
      errors: new Array<Error>(),
      retryMigrate: 0,
    }
  },
  mounted() {
    Auth.getToken()
    this.init()

    // if (session?.is_imported || session?.is_imported === null) {
    //   this.$router.push('/')
    //   console.log('proses data')
    // } else {
    //   this.init()
    // }
  },
  methods: {
    async init() {
      this.migrateUserData()
    },
    async migrateUserData() {
      try {
        const res = await MigrateUserDataModel.existingData()
        const existData = res.data as ExistingUserData
        // console.log(existData)
        const objectKeys = Object.keys(existData)
          .map(val => val)
          .filter(val => !excludeKey.includes(val))
        this.dataLength = objectKeys.length
        from(objectKeys)
          .pipe(
            mergeMap(key => {
              const existingUserDataProps = key as Key
              const existingUserData: UserData =
                existData[existingUserDataProps]
              return from(
                MigrateUserDataModel.postData(key, existingUserData)
              ).pipe(
                switchMap(resp => {
                  return of(resp).pipe(
                    map(resp => {
                      this.progress =
                        this.progress + Math.round(100 / objectKeys.length)
                      return resp
                    })
                  )
                }),
                catchError(error => {
                  this.errors.push({
                    name: key,
                    data: existingUserData,
                  })
                  return of(error)
                })
              )
            }),
            takeLast(1)
          )
          .subscribe(async () => {
            if (!this.errors.length) {
              const res = await MigrateUserDataModel.updateStatus()
              if (res.success) {
                this.handleSuccessImport(res.success)
              }
            } else {
              this.handleRetryImport()
            }
          })
      } catch (error) {
        console.log(error)
        this.handleRetryImport()
      }
    },
    async handleRetryImport() {
      if (this.retryMigrate < 3) {
        this.retryMigrate = this.retryMigrate + 1
        this.init()
      } else {
        Auth.logout()
        window.location.assign('/login')
      }
    },
    async handleRetryImportData(data: Error) {
      try {
        const res = await MigrateUserDataModel.postData(data.name, data.data)
        if (res.success) {
          this.progress = this.progress + Math.round(100 / this.dataLength)
          const i = this.errors.findIndex(val => val.name === data.name)
          if (~i) {
            this.errors.splice(i, 1)
            if (!this.errors.length) {
              const res = await MigrateUserDataModel.updateStatus()
              if (res.success) {
                this.handleSuccessImport(res.success)
              }
            }
          }
        }
      } catch (error) {
        this.errors.push({ name: data.name, data: data.data })
      }
    },
    async handleSuccessImport(isImported: boolean) {
      const auth: Token | undefined = await Auth.getToken()
      if (auth) {
        /* eslint-disable */
        const session = {
          ...auth,
          is_imported: isImported,
        }
        Auth.setToken(session)
        this.$router.push('/')
      }
    },
  },
})
</script>

<style>
.container {
  position: relative;
}
.navbar-logo {
  width: 136px;
  height: 26px;
}

.main-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  position: relative;
}

.main-card > h4 {
  font-size: 18px;
}

.main-card > h5 {
  font-size: 18px;
}

.footer {
  color: #c4c4c4;
  font-size: 18px;
}

.wrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
}
</style>
