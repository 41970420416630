<template>
  <div
    id="scroll-1"
    class="min-h-page-login w-full bg-color-primary p-0 relative justify-center items-center flex overflow-hidden"
  >
    <div class="decor-top"></div>
    <div class="decor-bottom"></div>
    <transition name="fade" mode="out-in">
      <div
        v-if="requestSuccess"
        class="m-auto max-w-md px-6 py-12 border-0 sm:rounded-3xl relative w-full z-11"
      >
        <img
          src="/assets/images/modal/illustration-test-completed.svg"
          class="w-52 m-auto"
          alt=""
        />
        <p
          class="pt-6 pb-2 text-[#212121] text-2xl text-center font-desain font-semibold"
        >
          Email Berhasil Dikirim!
        </p>
        <p
          class="pb-6 text-[#212121] text-base text-center font-solusi font-medium"
        >
          Cek kotak masuk/spam kamu ya, kami telah mengirimkan email berisi link
          untuk mengatur ulang kata sandi ke
          <span class="text-[#212121] text-base font-solusi font-semibold">{{
            emailRequest
          }}</span>
        </p>
        <router-link
          to="/register"
          class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-6 bg-[#00446F] text-white"
        >
          Kembali ke Halaman Masuk
        </router-link>
      </div>
      <div
        v-else
        class="m-auto max-w-md px-6 py-12 border-0 sm:rounded-3xl relative w-full z-11"
      >
        <img src="/assets/images/mentor/logo-primary.svg" class="m-auto" />
        <p
          class="pt-6 pb-2 text-[#212121] text-2xl text-left font-desain font-semibold"
        >
          Lupa Kata Sandi?
        </p>
        <p
          class="pb-6 text-[#212121] text-sm text-left font-solusi font-medium"
        >
          Masukan alamat email Anda, kami akan mengirimkan link untuk atur ulang
          kata sandi.
        </p>
        <form id="login" @submit.prevent="submitRequest">
          <div class="mb-6">
            <div
              class="floating relative z-0 w-full m-0 rounded-sm inline-flex items-center"
              :class="errorFieldEmail ? 'border-error' : 'border-black-80'"
            >
              <div class="p-2 z-10">
                <svg
                  width="24"
                  height="24"
                  viewBox="0 0 24 24"
                  class="w-6"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    d="M19.4067 4H4.59329C3.64086 4.00091 2.72786 4.37728 2.05418 5.04547C1.38042 5.71366 1.00092 6.61911 1 7.5636V16.4364C1.00092 17.381 1.38042 18.2864 2.05418 18.9545C2.72793 19.6227 3.64093 19.9991 4.59329 20H19.4067C20.3591 19.9991 21.2721 19.6227 21.9458 18.9545C22.6196 18.2863 22.9991 17.3809 23 16.4364V7.5636C22.9991 6.61904 22.6196 5.71359 21.9458 5.04547C21.2721 4.37728 20.3591 4.00091 19.4067 4ZM21.5334 16.4366C21.5334 17.6011 20.581 18.5457 19.4067 18.5457H4.59329C3.41903 18.5457 2.46661 17.6011 2.46661 16.4366V7.56378C2.46661 6.39923 3.41903 5.45467 4.59329 5.45467H19.4067C20.581 5.45467 21.5334 6.39923 21.5334 7.56378V16.4366Z"
                    fill="#212121"
                  />
                  <path
                    d="M19.8524 7.12192C19.5655 6.83827 19.1017 6.83827 18.8147 7.12192L12.0002 13.8811L5.18569 7.12192C4.89784 6.84646 4.44043 6.851 4.15718 7.13101C3.87485 7.41191 3.87025 7.86555 4.14801 8.15101L8.03013 12.0001L4.14801 15.8492C4.00685 15.9847 3.92618 16.1711 3.92435 16.3665C3.92251 16.5611 4.00043 16.7492 4.13885 16.8874C4.27818 17.0247 4.46792 17.102 4.66411 17.1002C4.86119 17.0984 5.0491 17.0183 5.1857 16.8783L9.06688 13.0283L11.4814 15.4229V15.4238C11.7683 15.7074 12.2321 15.7074 12.5191 15.4238L14.9336 13.0283L18.8147 16.8774V16.8783C19.1026 17.1538 19.56 17.1492 19.8432 16.8692C20.1256 16.5883 20.1302 16.1347 19.8524 15.8492L15.9703 12.0001L19.8515 8.15099H19.8524C20.1384 7.86643 20.1384 7.40644 19.8524 7.12188L19.8524 7.12192Z"
                    fill="#212121"
                  />
                </svg>
              </div>
              <input
                id="requestForgotPassword"
                type="email"
                name="requestForgotPassword"
                placeholder=" "
                required
                v-focus
                v-model="emailRequest"
                autocomplete="off"
                class="py-2.5 block w-full px-0 mt-0 bg-transparent border-0 border-b-2 appearance-none border-transparent focus:outline-none focus:ring-0"
                :class="
                  errorFieldEmail
                    ? 'focus:border-[#DE1306]'
                    : 'focus:border-[#212121]'
                "
              />

              <label
                for="requestForgotPassword"
                :class="errorFieldEmail ? 'text-[#DE1306]' : 'text-[#212121]'"
                class="absolute duration-300 top-2.5 left-10 -z-1 origin-0 bg-color-primary m-0 px-1.5 py-0 font-solusi rounded-md"
              >
                Email
              </label>
            </div>
            <p
              v-if="errorFieldEmail"
              class="text-xs text-[#DE1306] font-solusi"
            >
              {{ errorEmailMessage }}
            </p>
          </div>
          <button
            v-if="!requestProcess"
            type="submit"
            class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-6"
            :class="
              checkEmail
                ? 'bg-[#00446F] text-white'
                : 'bg-border text-[#9E9E9E] cursor-not-allowed'
            "
            :disabled="!checkEmail"
          >
            Kirim Email
          </button>
          <button
            v-else
            class="w-full px-6 py-2.5 rounded-full font-solusi font-semibold text-base h-fit-content flex items-center outline-none focus:outline-none justify-center duration-500 mb-6 bg-border text-[#9E9E9E] cursor-wait"
            disabled
          >
            <svg
              aria-hidden="true"
              focusable="false"
              data-prefix="fas"
              data-icon="spinner"
              class="w-6 h-6 animate-spin"
              role="img"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 512 512"
            >
              <path
                fill="#00446F"
                d="M304 48c0 26.51-21.49 48-48 48s-48-21.49-48-48 21.49-48 48-48 48 21.49 48 48zm-48 368c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zm208-208c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.49-48-48-48zM96 256c0-26.51-21.49-48-48-48S0 229.49 0 256s21.49 48 48 48 48-21.49 48-48zm12.922 99.078c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.491-48-48-48zm294.156 0c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48c0-26.509-21.49-48-48-48zM108.922 60.922c-26.51 0-48 21.49-48 48s21.49 48 48 48 48-21.49 48-48-21.491-48-48-48z"
              ></path>
            </svg>
          </button>
          <p class="text-center font-solusi text-sm">
            Kembali ke halaman
            <router-link
              to="/register"
              class="font-solusi font-semibold text-tritary text-sm cursor-pointer"
            >
              Masuk
            </router-link>
            atau
            <router-link
              to="/register?q=daftar"
              class="font-solusi font-semibold text-tritary text-sm cursor-pointer"
            >
              Daftar
            </router-link>
          </p>
        </form>
      </div>
    </transition>
  </div>
</template>

<script lang="ts">
import { defineComponent } from 'vue'
import UserModel from '@/models/UserModel'

export default defineComponent({
  name: 'Forgot Password',
  data() {
    return {
      emailRequest: '',
      errorFieldEmail: false,
      errorEmailMessage: '',
      requestProcess: false,
      requestSuccess: false,
    }
  },
  computed: {
    checkEmail(): boolean {
      if (
        //eslint-disable-next-line
        /^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,3})+$/.test(this.emailRequest)
      ) {
        return true
      } else {
        return false
      }
    },
  },
  methods: {
    async submitRequest() {
      this.requestProcess = true
      await UserModel.requestResetPassword(this.emailRequest)
        .then(res => {
          this.requestProcess = false
          this.requestSuccess = true
        })
        .catch(err => {
          this.requestProcess = false
          this.errorFieldEmail = true
          this.errorEmailMessage = err.response.data.errors[0].message
        })
    },
  },
})
</script>

<style scoped>
.fade-enter-active {
  transition: opacity 0.5s ease;
}

.fade-leave-active {
  transition: opacity 0s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

#scroll-1::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
  background-color: #f5f5f5;
}
#scroll-1::-webkit-scrollbar {
  width: 4px;
  background-color: #f5f5f5;
}
#scroll-1::-webkit-scrollbar-thumb {
  border-radius: 6px;
  background-color: #9e9e9e;
}
::v-deep(.p-inputtext) {
  padding: 0;
}
</style>
